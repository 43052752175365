import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import HeroDetail from 'components/elements/HeroDetail'
import Content from 'components/shared/Content'
import ButtonDefault from 'components/elements/ButtonDefault'

// Shared
import Image from 'components/shared/Image'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import TitleDefault from 'components/elements/TitleDefault'
import ParseContent from 'components/shared/ParseContent'

const AboutImage = styled(Image)`
  width: 540px;
  height: auto;
`

const AboutContent = styled(ParseContent)`
   & h2 {
   font-size: ${props => props.theme.font.size.l};
   font-weight: ${props => props.theme.font.weight['700']};
   color: ${props => props.theme.color.text.main};

   margin-bottom: 30px;

&:after {
 content: '';
 width: 37px;
 height: 3px;
 display: block;
 margin-top: 17px;
 background-color: ${props => props.theme.color.face.contrast};
}

   @media (max-width: 991px){
    font-size: ${props => props.theme.font.size.xm};
   }

 }
`

const Title = styled(TitleDefault)`
font-size: 32px;
margin-bottom: 2rem;
`

const PageTemplate = ({
  data: {
    page: { title, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <HeroDetail fields={acf.banner} title={title} />
      
      <section>
        <div className="container py-lg-5">
          <div className="row py-5 align-items-start">
            {/* <div className="col-lg-6 mb-4 mb-lg-0">
              <AboutImage src={acf.aboutUs.image} />
            </div> */}
            <div className="col-lg-10 pl-lg-5">
              <Title withStripe>Over ons</Title>
              <AboutContent content={acf.aboutUs.description} className="mb-4" />
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }

      aboutUs: about_us {
        image {
          localFile {
            publicURL
          }
        }
        description
      }

      services
   
    }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
